import React from "react"
import { Helmet } from "react-helmet"

import { Link, withPrefix, graphql } from "gatsby"
import Layout from "@layout/layout"
import SEO from "@layout/seo";
import PostCategory from "@blog/post-category.js"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription}
      />
      <Helmet
        meta={[
          {
            property: `og:title`,
            content: post.frontmatter.title.trim()
          },
          {
            property: `og:url`,
            content: `https://sasafoundation.com/${post.frontmatter.slug}`
          },
        ]}
      >
        <script type="application/ld+json">
        {
          `
          {"@context":"http:\\/\\/schema.org","@type":"WebSite","@id":"#website","url":"https:\\/\\/tiliamed.pl\\/","name":"Tiliamed"}
          `
        }
        </script>
      </Helmet>
      <div className="content">
        <main>
          <section
            className="page-margin post-single post-849 post type-post status-publish format-standard has-post-thumbnail hentry category-ginekologia category-tiliamed tag-dobry-ginekolog-wolomin">
            <div className="container-1075">
              <div className="row">
                <div className="col-sm-9">
                  <Link to={"/edukacja/"} className="post-single__back">
                    <svg width="11" height="20" viewBox="0 0 11 20" xmlns="http://www.w3.org/2000/svg">
                      <title>strzałka
                      lewo</title>
                      <path
                        d="M3.316 9.622l7.299 7.298a1.375 1.375 0 0 1-1.944 1.944l-8.25-8.25c-.26-.25-.421-.6-.421-.99 0-.394.161-.745.42-.995L8.67.38c.25-.234.585-.38.955-.38a1.375 1.375 0 0 1 .895 2.418L3.316 9.622z"
                        fill="#9F9F9F" fill-rule="evenodd"></path>
                    </svg>
                    <span>Wróć do artykułów</span>
                  </Link>
                  <p className="post-single__category">
                    {post.frontmatter.category.map((category, key) => (
                      <PostCategory category={category} key={key}/>
                    ))}
                  </p>
                  <h1 className="post-single__heading">
                    {post.frontmatter.title}
                  </h1>
                  <div className="post-single__content" dangerouslySetInnerHTML={{__html: post.html}} />
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        slug
        author
        category
        metaDescription
        metaTitle  
      }
    }
  }
`
