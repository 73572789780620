import React from "react";

import './post-category.css'

export default ({category}) => {
  switch(category) {
    case 'Tiliamed':
      return <span className="category-color-1">Tiliamed</span>
    case 'Opieka w ciąży':
      return <span className="category-color-2">Opieka w ciąży</span>
    case 'Ginekologia':
      return <span className="category-color-3">Ginekologia</span>
    case 'Badania USG w ciąży':
      return <span className="category-color-4">Badania USG w ciąży</span>
  }
}
